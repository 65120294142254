<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right;">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>

          <!-- //Table View heade -->
          <template>
            <TableView
              :loans="loans"
              :isLoading="isLoading"
              :headerTittle="headerTittle"
              :showAddButton="showAddButton"
              :rows="rows"
              @forPagination="forPagination"
              :updatedIsSelectAll="isSelectAll"
              @updateStatus="updateStatus"
              @showModal="showVerficationModal"
              @editData="editData"
              @deleteData="deleteData"
              @confirmationBulkAction="confirmationBulkAction"
            />
          </template>
        </div>

        <!-- // -->
      </div>
    </div>
  </div>
</template>

<script>
import VerficationModal from "./VerficationModal";
import TableView from "@/view/pages/Voucher/TableView";
import axios from "axios";
export default {
  page: {
    title: "Collateral",
  },

  components: {
    VerficationModal,
    TableView,
  },

  data() {
    return {
      loans: [],
      unSelectedCheckbox: [],
      alert: false,
      loading: true,
      alertText: "",
      isModel: false,
      modalBodyText: "",
      actionType: "",
      modalTitle: "",
      buttonStatus: "",
      headerTittle: "Vouchers",
      showModal: false,
      showAddButton: false,
      checkedNames: [],
      customerId: "",
      userObj: {},
      isShowModel: false,
      showDeleteModal: false,
      isSelectAll: true,
      UserType: "",
      isLoading: false,
      tab: "",
      perPage: 10,
    };
  },

  methods: {
    showVerficationModal(value, object) {
      this.isShowModel = value;
      this.showModal = value;
      this.userObj = object;
      if (this.userObj.item.status == "1") {
        this.actionType = "active";
      }
      if (this.userObj.item.status == "0") {
        this.actionType = "deactive";
      }
    },
    confirmationBulkAction(status, checkedNames) {
      this.checkedNames = checkedNames;

      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Active This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactive This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },
    closeModal(value) {
      this.isShowModel = value;
    },

    forPagination(page, perPage) {
      this.perPage = perPage;
      this.getRecord(page);
    },

    deleteRow(getUser) {
      this.rows = this.rows - 1;
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert = true;
      this.alertText = "Record deleted Successfully";
      this.hideAlert();
    },

    singlechangeStatus(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      if (this.actionType == "active") {
        this.alertText = "Record deactivated successfully";
      }
      if (this.actionType == "deactive") {
        this.alertText = "Record activated successfully";
      }
      this.hideAlert();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteData(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete";
    },

    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/voucher/bulkAction", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["my-modal"].hide();
              this.getRecord();
              this.alert = true;
              if (this.status == "delete") {
                this.alertText = "Record deleted successfully";
              }
              if (this.status == "active") {
                this.alertText = "Record activated successfully";
              }
              if (this.status == "deactive") {
                this.alertText = "Record deactivated successfully";
              }
              this.checkedNames = [];
              this.hideAlert();
              this.isSelectAll = false;
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getRecord(page) {
      let loginData = JSON.parse(localStorage.getItem("currentUser"));

      this.isLoading = true;
      if (typeof page === "undefined") {
        page = 1;
      }
      const data = {
        type: loginData.type,
        customer_id: loginData.id,
        per_page: this.perPage,
        page: page,
        tab: this.tab,
        pagination: true,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/voucher/list", data, {
            headers,
          })
          .then((response) => {
            this.loans = response.data.data.data;
            this.rows = response.data.data.pagination.total;
            this.loading = false;
            this.isLoading = false;

            if ("alertmessage" in localStorage) {
              this.alert = true;
              this.alertText = JSON.parse(localStorage.getItem("alertmessage"));
              this.hideAlert();
              localStorage.removeItem("alertmessage");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    editData(object) {
      localStorage.setItem("editvoucher", JSON.stringify(object.item));
      this.$router.push({ name: "edit_vouchers" });
    },
    updateStatus(data) {
      this.isSelectAll = data;
    },
  },

  mounted() {
    document.title = "Vouchers";
    localStorage.removeItem("editskip");
    this.totalRows = this.loans.length;
    this.getRecord();
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
